@import '../semantic/dist/semantic.css';

.ui.form .fields > .field {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

// Fix colision between Bootstrap & Semantic
.ui.grid > .row {
  margin-left: 0;
  margin-right: 0;
}
